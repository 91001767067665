<template>
  <div>
    <Header />
    <slot></slot>
  </div>
</template>

<script>
import Header from "@/components/Header";
import {mapGetters} from "vuex";

export default {
  name: 'main-layout',
  computed: mapGetters(["getStep"]),
  components: {Header}
}
</script>