<template>
  <div class="content">
      <sign-up v-if="getStep === 0" />
      <Questions v-if="getStep > 0" />
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import SignUp from "@/components/SignUp";
import Questions from "@/components/Questions";

export default {
  name: 'Content',
  computed: mapGetters(["getStep"]),
  components: {
    SignUp,
    Questions,
  }
}
</script>