<template>
  <div>
    <div class="answer answer_default" v-on:click="selected" :class="{'answer_active': answer.select}" v-if="type === 'check'">
      <div class="answer-checkbox">
        <input type="checkbox" v-model="answer.select" v-on:click="selected" hidden>
        <div class="answer-checkbox__box"></div>
      </div>
      <div class="answer__block">
        <h2 class="answer__title">{{ answer.name }}</h2>
        <p class="answer__desc">{{ answer.desc }}</p>
      </div>
    </div>

    <div class="answer answer_assessment" v-on:click="selected" :class="{'answer_active': answer.select}" v-if="type === 'assessment'">
      <div class="answer__block">
        <h2 class="answer__title">{{ answer.name }}</h2>
        <p class="answer__desc">{{ answer.desc }}</p>
      </div>
      <div class="answer__row">
        <label class="answer__label answer__row-item">
          <div class="answer-radio">
            <input type="radio" value="1" v-model="answer.select" v-on:click="selected" hidden>
            <div class="answer-radio__box"></div>
          </div>
          Никак
        </label>
        <label class="answer__label answer__row-item">
          <div class="answer-radio">
            <input type="radio" value="2" v-model="answer.select" v-on:click="selected" hidden>
            <div class="answer-radio__box"></div>
          </div>
          Слабо
        </label>
        <label class="answer__label answer__row-item">
          <div class="answer-radio">
            <input type="radio" value="3" v-model="answer.select" v-on:click="selected" hidden>
            <div class="answer-radio__box"></div>
          </div>
          Средне
        </label>
        <label class="answer__label answer__row-item">
          <div class="answer-radio">
            <input type="radio" value="4" v-model="answer.select" v-on:click="selected" hidden>
            <div class="answer-radio__box"></div>
          </div>
          Сильно
        </label>
        <label class="answer__label answer__row-item">
          <div class="answer-radio">
            <input type="radio" value="5" v-model="answer.select" v-on:click="selected" hidden>
            <div class="answer-radio__box"></div>
          </div>
          Очень сильно
        </label>
      </div>
    </div>

    <div class="answer answer_figure" v-on:click="selected" :class="{'answer_active': answer.select}" v-if="type === 'figure'">
      <input type="radio" name="figure" v-model="answer.select" v-on:click="selected" hidden>
      <img class="answer__image" :src="'img/' + answer.type + '.png'">
    </div>

    <div class="answer__line" v-if="type === 'profession' || type === 'activities'">
      <div class="answer answer_or" v-on:click="selected(item.type)" :class="{'answer_active': answer.select === item.type}" v-for="item in JSON.parse(answer.name)">
        <div class="answer-radio">
          <input type="radio" :value="item.type" v-model="answer.select" v-on:click="selected" hidden>
          <div class="answer-radio__box"></div>
        </div>
        <div class="answer__block">
          <h2 class="answer__title">{{ item.name }}</h2>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapGetters} from "vuex";

export default {
  name: 'Answer',
  props: ['type', 'answer'],
  computed: mapGetters(["getStep", "getAnswerSelected", "getAnswers"]),
  methods: {
    selected(type = null) {
      if (this.type === 'check') {
        if (this.answer.select) {
          this.answer.select = false;
        } else {
          if (this.getAnswerSelected(this.getStep) <= 1) {
            this.answer.select = !this.answer.select;
          }
        }
      } else if (this.type === 'figure') {
        let answers = this.getAnswers(this.getStep);

        answers.forEach((awr) => {
          awr.select = false;
        });

        this.answer.select = !this.answer.select;
      } else if (this.type === 'profession' || this.type === 'activities') {
        this.answer.select = type;
      }
    }
  }
}
</script>

<style scoped lang="scss">
.answer {
  display: flex;
  border-radius: 4px;
  margin-bottom: 20px;
  background-color: #fff;
  transition: border .15s linear;
  border: 1px solid transparent;
  &_default {
    cursor: pointer;
    padding: 32px 50px;
    align-items: center;
    @media screen and (max-width: 768px) {
      padding: 20px 27px 28px 36px;
    }
  }
  &_assessment {
    padding: 40px 50px;
    flex-direction: column;
    @media screen and (max-width: 768px) {
      padding: 40px 32px 13px;
    }
  }
  &_figure {
    height: 100%;
    cursor: pointer;
    padding: 90px 55px;
    align-items: center;
    justify-content: center;
    @media screen and (max-width: 768px) {
      padding: 70px 55px;
    }
  }
  &_or {
    width: 45%;
    height: 100%;
    flex: 0 0 45%;
    cursor: pointer;
    margin-bottom: 0;
    padding: 32px 40px;
    align-items: center;
    @media screen and (max-width: 768px) {
      width: 100%;
      flex: 0 0 100%;
      &:first-child {
        position: relative;
        margin-bottom: 52px;
        &:after {
          left: 50%;
          color: #A8ABC1;
          content: 'или';
          font-size: 24px;
          font-weight: 700;
          line-height: 28px;
          position: absolute;
          top: calc(100% + 14px);
          transform: translateX(-50%);
        }
      }
    }
    .answer-radio {
      margin-right: 32px;
    }
  }
  &_active {
    border-color: #244CC8;
  }
  &__image {
    max-width: 100%;
    max-height: 100%;
  }
  &__line {
    display: flex;
    position: relative;
    align-items: center;
    margin-bottom: 20px;
    justify-content: space-between;
    @media screen and (max-width: 768px) {
      margin-bottom: 40px;
      padding-bottom: 40px;
      align-items: baseline;
      flex-direction: column;
      border-bottom: 1px solid #CFD1DF;
    }
    &:before {
      top: 50%;
      left: 50%;
      color: #A8ABC1;
      content: 'или';
      font-size: 24px;
      font-weight: 700;
      line-height: 28px;
      position: absolute;
      transform: translateX(-50%) translateY(-50%);
      @media screen and (max-width: 768px) {
        content: unset;
      }
    }
  }
  &__row {
    display: flex;
    margin-top: 24px;
    align-items: center;
    @media screen and (max-width: 992px) {
      flex-wrap: wrap;
    }
    &-item {
      white-space: nowrap;
      @media screen and (max-width: 768px) {
        margin-bottom: 27px;
      }
      &:not(:last-child) {
        margin-right: 32px;
      }
    }
  }
  &__label {
    display: flex;
    cursor: pointer;
    align-items: center;
  }
  &__title {
    font-size: 24px;
    font-weight: 700;
    line-height: 28px;
    color: $primary-text;
    @media screen and (max-width: 768px) {
      font-size: 18px;
      line-height: 22px;
    }
  }
  &__desc {
    margin-top: 4px;
    font-size: 18px;
    line-height: 22px;
    color: $secondary-text;
  }
  &-radio {
    flex: 0 0 24px;
    margin-right: 12px;
    input {
      &:checked {
        & ~ .answer-radio__box {
          border-color: transparent;
          background-color: $primary-color;
          &:before {
            opacity: 1;
          }
        }
      }
    }
    &__box {
      width: 24px;
      height: 24px;
      display: flex;
      cursor: pointer;
      border-radius: 50%;
      position: relative;
      align-items: center;
      background-color: #fff;
      transition: .15s linear;
      justify-content: center;
      border: 1px solid #CFD1DF;
      &:before {
        top: 50%;
        left: 50%;
        opacity: 0;
        content: '';
        width: 12px;
        height: 12px;
        border-radius: 50%;
        position: absolute;
        background-color: #fff;
        transform: translateX(-50%) translateY(-50%);
      }
    }
  }
  &-checkbox {
    flex: 0 0 20px;
    margin-right: 50px;
    @media screen and (max-width: 768px) {
      flex: 0 0 28px;
      margin-right: 25px;
    }
    input {
      &:checked {
        & ~ .answer-checkbox__box {
          border-color: transparent;
          background-color: $primary-color;
        }
      }
    }
    &__box {
      width: 20px;
      height: 20px;
      display: flex;
      cursor: pointer;
      border-radius: 4px;
      align-items: center;
      background-color: #fff;
      transition: .15s linear;
      justify-content: center;
      border: 1px solid #CFD1DF;
      @media screen and (max-width: 768px) {
        width: 28px;
        height: 28px;
      }
      &:before {
        width: 12px;
        content: '';
        height: 10px;
        display: block;
        position: relative;
        background-image: url("../assets/checkbox-check.png");
      }
    }
  }
}
</style>