import Vue from 'vue'
import VueRouter from 'vue-router'
import TestPage from "@/pages/TestPage";

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'test',
    component: TestPage
  },
  {
    path: '/result/:id',
    name: 'result',
    component: () => import('@/pages/ResultPage')
  }
]

const router = new VueRouter({
  mode: 'history',
  // base: process.env.BASE_URL,
  base: publicPath,
  publicPath: publicPath,
  routes
})

export default router
