import {Radar, mixins} from "vue-chartjs";

export default {
    extends: Radar,
    mixins: [mixins.reactiveProp],
    props: ['chartData'],
    data() {
        return {
            locChart: {
                labels: ["Индивидуальные мотивы", "Положительные мотивы", "Социальные мотивы", "Отрицательные мотивы"],
                datasets: [{
                    borderWidth: 0,
                    data: this.chartData,
                    backgroundColor: '#244CC8',
                    pointRadius: 0,
                    pointHoverRadius: 0,
                }]
            },
            options: {
                responsive: true,
                maintainAspectRatio: true,
                tooltips: {
                    enabled: false
                },
                legend: {
                    display: false
                },
                scale: {
                    ticks: {
                        display: false,
                        maxTicksLimit: 6,
                        suggestedMin: 0,
                        suggestedMax: 2
                    },
                    gridLines: {
                        circular: false,
                        color: 'transparent'
                    },
                    angleLines: {
                        color: 'transparent'
                    }
                },
            },
        }
    },
    mounted() {
        this.renderChart(this.locChart, this.options)
    }
}