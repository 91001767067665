<template>
  <div class="actions">
    <div class="actions__inner">
      <button class="actions__button actions__button_outline" v-show="getStep > 1" v-on:click="prevStep">
        <svg width="20" height="16" viewBox="0 0 20 16" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path fill-rule="evenodd" clip-rule="evenodd" d="M9.06005 2.17441C9.44006 1.77365 9.42325 1.14071 9.02249 0.760693C8.62173 0.380678 7.98879 0.397495 7.60877 0.798253L1.38187 7.36507C1.07811 7.53668 0.873047 7.86256 0.873047 8.23633C0.873047 8.26851 0.874567 8.30033 0.877539 8.33173C0.886406 8.58257 0.989143 8.8303 1.18441 9.01774L7.6419 15.2165C8.04033 15.599 8.67336 15.586 9.05582 15.1876C9.43828 14.7892 9.42534 14.1561 9.02692 13.7737L4.30018 9.23633H18.9168C19.4691 9.23633 19.9168 8.78862 19.9168 8.23633C19.9168 7.68405 19.4691 7.23633 18.9168 7.23633H4.26014L9.06005 2.17441Z" fill="currentColor"/>
        </svg>
        <span>Назад</span>
      </button>
      <button class="actions__button actions__button_primary" v-on:click="nextStep">
        <span>Далее</span>
        <svg width="20" height="16" viewBox="0 0 20 16" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path fill-rule="evenodd" clip-rule="evenodd" d="M11.731 13.807C11.351 14.2078 11.3678 14.8407 11.7685 15.2208C12.1693 15.6008 12.8022 15.5839 13.1822 15.1832L19.4091 8.61638C19.7129 8.44476 19.918 8.11889 19.918 7.74512C19.918 7.71295 19.9165 7.68113 19.9135 7.64974C19.9046 7.39889 19.8019 7.15115 19.6066 6.9637L13.1491 0.764953C12.7507 0.382492 12.1177 0.395435 11.7352 0.79386C11.3527 1.19228 11.3657 1.82532 11.7641 2.20778L16.4908 6.74511L1.87422 6.74511C1.32194 6.74511 0.874223 7.19283 0.874222 7.74511C0.874222 8.2974 1.32194 8.74511 1.87422 8.74511L16.5309 8.74512L11.731 13.807Z" fill="currentColor"/>
        </svg>
      </button>
    </div>
  </div>
</template>

<script>
import {mapGetters, mapMutations} from "vuex";

export default {
  name: 'Actions',
  computed: mapGetters(["getStep", "getQuestionsCount", "getAnswerSelected", "getQuestionType", "getAnswerCount", "getTester"]),
  methods: {
    ...mapMutations(["changeStep", "setStep"]),
    nextStep() {
      if (this.getStep !== this.getQuestionsCount) {
        if (this.getQuestionType(this.getStep) === "check") {
          if (this.getAnswerSelected(this.getStep) !== 0) {
            this.changeStep(1);
            this.scrollToTop();
          }
        } else if (this.getQuestionType(this.getStep) === "assessment" || this.getQuestionType(this.getStep) === "profession") {
          if (this.getAnswerSelected(this.getStep) === this.getAnswerCount(this.getStep)) {
            this.changeStep(1);
            this.scrollToTop();
          }
        } else if (this.getQuestionType(this.getStep) === "figure") {
          if (this.getAnswerSelected(this.getStep) === 1) {
            this.changeStep(1);
            this.scrollToTop();
          }
        }
      } else {
        if (this.getQuestionType(this.getStep) === "activities") {
          if (this.getAnswerSelected(this.getStep) === this.getAnswerCount(this.getStep)) {
            this.$store.dispatch('sendQuestions', this.getTester);
            this.$router.push({name: 'result', params: {id: this.getTester.id}})
            this.setStep(0);
            this.scrollToTop();
          }
        }
      }
    },
    prevStep() {
      this.scrollToTop();
      this.changeStep(0);
    },
    scrollToTop() {
      document.querySelector(".progress").scrollIntoView({ behavior: 'smooth', block: 'start'});
    }
  }
}
</script>

<style scoped lang="scss">
.actions {
  margin-top: 36px;
  &__inner {
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
  &__button {
    outline: none;
    display: flex;
    cursor: pointer;
    font-size: 18px;
    font-weight: 700;
    line-height: 27px;
    padding: 14px 40px;
    border-radius: 4px;
    align-items: center;
    text-decoration: none;
    transition: .15s linear;
    border: 1px solid transparent;
    background-color: transparent;
    svg {
      &:not(:last-child) {
        margin-right: 8px;
      }
      &:not(:first-child) {
        margin-left: 8px;
      }
    }
    &:not(:last-child) {
      margin-right: 20px;
    }
    &_primary {
      color: #fff;
      background-color: $primary-color;
      &:hover {
        opacity: .8;
      }
    }
    &_outline {
      border-color: #9FAEBF;
      &:hover {
        color: #fff;
        background-color: #9FAEBF;
      }
      @media screen and (max-width: 768px) {
        height: 57px;
        flex-grow: 1;
        padding: 14px;
        align-items: center;
        justify-content: center;
        span {
          display: none;
        }
      }
    }
  }
}
</style>