<template>
    <main-layout>
      <Progress :full="false" v-if="getStep > 0" />
      <Content/>
    </main-layout>
</template>

<script>
import MainLayout from "@/layouts/MainLayout";
import SignUp from "@/components/SignUp";
import Content from "@/components/Content";
import Progress from "@/components/Progress";
import {mapGetters} from "vuex";

export default {
  name: 'test-page',
  computed: mapGetters(["getStep"]),
  components: {Progress, Content, SignUp, MainLayout},
  mounted() {
    this.$store.dispatch('fetchQuestions')
  }
}
</script>