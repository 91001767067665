<template>
  <div class="progress">
    <div class="container">
      <div class="progress__inner">
        <p class="progress__state">Пройдено: <span>{{ this.getPercent(full, true) }}%</span></p>
        <div class="progress__line"><div :style="'width: ' + this.getPercent(full) + '%'"></div></div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapGetters} from "vuex";

export default {
  name: 'Progress',
  props: ["full"],
  computed: mapGetters(["getPercent", "getQuestionsCount"]),
}
</script>

<style scoped lang="scss">
@import "../styles/_variables";

.progress {
  padding: 24px 0 48px;
  background-color: $secondary-background;
  @media print {
    display: none;
  }
  &__inner {
    display: flex;
    flex-direction: column;
  }
  &__state {
    font-size: 18px;
    text-align: left;
    line-height: 22px;
    margin-bottom: 8px;
    color: $secondary-text;
    justify-self: flex-start;
    span {
      color: $primary-text;
    }
  }
  &__line {
    width: 100%;
    height: 8px;
    overflow: hidden;
    border-radius: 4px;
    background-color: #fff;
    div {
      height: 100%;
      border-radius: 4px;
      background-color: $primary-color;
      transition: width .5s ease-in-out;
    }
  }
}
</style>