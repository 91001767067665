import axios from 'axios'

export default {
    state: {
        result: null
    },
    mutations: {
        updateResult(state, result) {
            state.result = result;
        }
    },
    actions: {
        fetchResult(ctx, resultId) {
            console.log(resultId);
            axios({
                method: 'post',
                headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
                url: "https://synergy-proftest.ru/api/results/get/",
                data: "id=" + resultId
            })
                .then((res) => {
                    if (res.data !== "404") {
                        ctx.commit('updateResult', res.data)
                    } else {
                        ctx.commit('updateResult', false)
                    }
                })
                .catch(() => {
                    ctx.commit('updateResult', false)
                })
        }
    },
    getters: {
        getResult(state) {
            return state.result;
        }
    }
}