import axios from 'axios';

export default {
    state: {
        questions: [],
    },
    mutations: {
        updateQuestions(state, questions) {
            state.questions = questions;
        },
    },
    actions: {
        async fetchQuestions(ctx) {
            let response = await fetch("https://synergy-proftest.ru/api/questions/get/");
            if (response.ok) {
                let json = await response.json();
                ctx.commit('updateQuestions', json);
            }
        },
        sendQuestions(ctx, tester) {
            let result = {
                tester: tester.id,
                questions: [],
                mergelead: tester.mergelead,
                url: publicPath
            };
            let questions = ctx.state.questions;

            questions.forEach((question) => {
                let answers = question.answers;
                let resAnswers = [];

                answers.forEach((answer) => {
                    if (answer.select !== false) {
                        resAnswers.push({
                            id: answer.id,
                            select: answer.select
                        });
                    }
                });

                if (resAnswers.length !== 0) {
                    let resQuestions = {
                        id: question.id,
                        answers: resAnswers
                    };

                    result.questions.push(resQuestions);
                }
            });

            axios({
                method: 'post',
                headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
                url: "https://synergy-proftest.ru/api/questions/send/",
                data: result
            })
                .then((res) => {
                    return true;
                })
        }
    },
    getters: {
        getResultId(state) {
            return state.resultId;
        },
        getQuestions(state) {
            return state.questions;
        },
        getQuestionType: (state) => (step) => {
            let result = null,
                questions = state.questions;

            questions.forEach((question) => {
                if (question.step === step) {
                    result = question.type;
                }
            });

            return result;
        },
        getQuestionByGroup: (state) => (step = 1) => {
            let result = null,
                questions = state.questions;

            questions.forEach((question) => {
                if (question.step === step) {
                    result = question;
                }
            });

            return result;
        },
        getQuestionsCount(state) {
            return state.questions.length;
        },
        getAnswerCount: (state) => (step) => {
            let result = 0,
                questions = state.questions;

            questions.forEach((question) => {
                if (question.step === step) {
                    result = question.answers.length;
                }
            });

            return result;
        },
        getAnswers: (state) => (step) => {
            let result = 0,
                questions = state.questions;

            questions.forEach((question) => {
                if (question.step === step) {
                    result = question.answers;
                }
            });

            return result;
        },
        getAnswerSelected: (state) => (step = 1) => {
            let result = 0,
                questions = state.questions;

            questions.forEach((question) => {
                if (question.step === step) {
                    let answers = question.answers;
                    answers.forEach((answer) => {
                        if (question.type === "check" || question.type === "figure") {
                            if (answer.select === true) {
                                result++;
                            }
                        } else if (question.type === "assessment") {
                            if (answer.select > 0) {
                                result++;
                            }
                        } else if (question.type === "profession" || question.type === "activities") {
                            if (answer.select !== false) {
                                result++;
                            }
                        }
                    });
                }
            });

            return result;
        }
    }
}