import axios from 'axios'

export default {
    state: {
        id: null,
        name: null,
        email: null,
        phone: null,
        mergelead: null,
        personalDataAgree: true
    },
    mutations: {
        setTester(state, tester) {
            state.id = tester.id;
            state.status = tester.status;
            state.mergelead = tester.mergelead;
        },
    },
    actions: {
        async sendTester(ctx) {
            axios({
                method: 'post',
                headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
                url: "https://synergy-proftest.ru/api/tester/register/",
                data: new URLSearchParams({name: ctx.state.name, email: ctx.state.email, phone: ctx.state.phone, url: complete_url})
            })
                .then((res) => {
                    ctx.commit("setTester", res.data);
                })
        }
    }
    ,
    getters: {
        validName(state) {
            return !(state.name === null || state.name.trim() === "");
        },
        validPhone(state) {
            return !(state.phone === null || state.phone.trim() === "");
        },
        validEmail(state) {
            let re = /^[\w-\.]+@[\w-]+\.[a-z]{2,4}$/i;
            return re.test(state.email);
        },
        getTester(state) {
            return state;
        }
    }
}