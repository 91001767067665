<template>
  <div class="sign-up">
    <div class="container">
      <div class="sign-up__inner">
        <form class="sign-up__form nolander" @submit.prevent="submit">
          <div class="sign-up__grid sign-up__grid_four">
            <div class="sign-up__group" :class="{'sign-up__group_invalid': nameError}">
              <input type="text" name="name" class="sign-up__input" placeholder="Ваше имя" v-model="getTester.name" autocomplete="off">
            </div>
            <div class="sign-up__group" :class="{'sign-up__group_invalid': phoneError}">
              <input type="text" name="phone" class="sign-up__input" placeholder="Телефон" autocomplete="off">
            </div>
            <div class="sign-up__group" :class="{'sign-up__group_invalid': emailError}">
              <input type="text" name="email" class="sign-up__input" placeholder="E-mail" v-model="getTester.email" autocomplete="off">
            </div>
            <div class="sign-up__group">
              <button class="sign-up__button" :disabled="getTester.personalDataAgree === false">Начать тестирование</button>
            </div>
          </div>
          <div class="sign-up__policy">
            <label class="sign-up__policy-label">
              <div class="sign-up__policy-checkbox">
                <input type="checkbox" name="personalDataAgree2" value="on" v-model="getTester.personalDataAgree" hidden>
                <div class="sign-up__policy-checkbox__box"></div>
              </div>
              <div class="sign-up__policy-text">Я даю согласие на обработку персональных данных, согласен на получение информационных рассылок от Университета «Синергия» и соглашаюсь c политикой конфиденциальности</div>
            </label>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import {mapGetters, mapMutations} from 'vuex'

export default {
  name: 'sign-up',
  data() {
    return {
      nameError: false,
      phoneError: false,
      emailError: false,
    }
  },
  computed: mapGetters(["getTester", "validEmail", "validPhone", "validName"]),
  methods: {
    ...mapMutations(["changeStep"]),
    submit() {
      this.getTester.phone = document.querySelector('input[name=phone]').value;

      this.nameError = !this.validName;
      this.phoneError = !this.validPhone;
      this.emailError = !this.validEmail;

      if (!this.nameError && !this.phoneError && !this.emailError) {
        this.$store.dispatch('sendTester');
        this.changeStep(1);
      }
    }
  }
}
</script>

<style scoped lang="scss">
.sign-up {
  padding: 64px 0;
  background-color: $primary-background;
  @media screen and (max-width: 768px) {
    padding: 24px 0 40px;
  }
  &__form {
    display: flex;
    flex-direction: column;
  }
  &__grid {
    display: grid;
    grid-column-gap: 20px;
    grid-template-columns: repeat(4, 1fr);
    @media screen and (max-width: 768px) {
      grid-row-gap: 12px;
      grid-template-columns: repeat(1, 1fr);
    }
  }
  &__group {
    &_invalid {
      & > input {
        border-color: $secondary-color;
        transition: border-color .15s linear;
      }
    }
  }
  &__input, &__button {
    width: 100%;
    outline: none;
    font-size: 18px;
    line-height: 27px;
    padding: 14px 20px;
    border-radius: 4px;
  }
  &__input {
    background-color: #fff;
    border: 1px solid $primary-border;
    &::placeholder {
      color: $secondary-text;
    }
  }
  &__button {
    color: #fff;
    cursor: pointer;
    font-weight: 700;
    text-align: center;
    white-space: nowrap;
    transition: .15s linear;
    border: 1px solid transparent;
    background-color: $secondary-color;
    &:not(:disabled) {
      &:hover {
        background-color: rgba($secondary-color, .8);
      }
    }
    &:disabled {
      opacity: .4;
      cursor: default;
    }
  }
  &__policy {
    display: block;
    margin-top: 24px;
    &-label {
      display: flex;
      align-items: flex-start;
    }
    &-checkbox {
      flex: 0 0 20px;
      margin-right: 16px;
      input {
        &:checked {
          & ~ .sign-up__policy-checkbox__box {
            background-color: $primary-color;
          }
        }
      }
      &__box {
        width: 20px;
        height: 20px;
        display: flex;
        cursor: pointer;
        border-radius: 4px;
        align-items: center;
        transition: .15s linear;
        background-color: #fff;
        justify-content: center;
        &:before {
          width: 12px;
          content: '';
          height: 10px;
          display: block;
          position: relative;
          background-image: url("../assets/checkbox-check.png");
        }
      }
    }
    &-text {
      flex-grow: 1;
      font-size: 12px;
      max-width: 540px;
      line-height: 15px;
    }
  }
}
</style>