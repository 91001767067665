import {Radar, mixins} from "vue-chartjs";

export default {
    extends: Radar,
    mixins: [mixins.reactiveProp],
    props: ['chartData'],
    data() {
        return {
            locChart: {
                labels: ['Предпринимательский', 'Социальны', 'Артистический', 'Интеллектуальный', 'Конвенциальный', 'Реалистический'],
                datasets: [{
                    data: this.chartData,
                    backgroundColor: 'rgba(36, 76, 200, 0.3)',
                    pointBackgroundColor: '#244CC8',
                    borderColor: '#244CC8',
                    borderWidth: 1,
                    pointRadius: 2,
                    pointHoverRadius: 2,
                }]
            },
            options: {
                responsive: true,
                maintainAspectRatio: true,
                tooltips: {
                    enabled: false
                },
                legend: {
                    display: false
                },
                scale: {
                    ticks: {
                        display: false,
                        maxTicksLimit: 6,
                        suggestedMin: 0,
                        suggestedMax: 20
                    },
                    gridLines: {
                        circular: true,
                        color: '#CFD1DF'
                    },
                    angleLines: {
                        color: '#181940'
                    }
                },
            },
        }
    },
    mounted() {
        this.renderChart(this.locChart, this.options)
    }
}