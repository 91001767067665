import Vue from 'vue'
import Vuex from 'vuex'
import step from "@/store/modules/step";
import question from "@/store/modules/question";
import tester from "@/store/modules/tester";
import result from "@/store/modules/result";

Vue.use(Vuex)

export default new Vuex.Store({
  state: {},
  mutations: {},
  actions: {},
  modules: {
    step,
    question,
    tester,
    result,
  }
})
