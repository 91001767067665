<template>
  <div class="questions">
    <div class="container">
      <div class="questions__inner">

        <div class="questions__header">
          <div class="questions-counts">
            <p><span>{{ getStep }}</span>/{{ getQuestionsCount }}</p>
          </div>
          <div class="questions__block">
            <h2 class="questions__title" v-html="getQuestionByGroup(getStep).name"></h2>
            <p class="questions__desc" v-if="getQuestionByGroup(getStep).desc !== null">{{ getQuestionByGroup(getStep).desc }}</p>
          </div>
        </div>

        <div class="questions__content">
          <div class="answers" :class="{'answers_grid': getQuestionByGroup(getStep).type === 'figure'}">
            <Answer v-for="answer in getQuestionByGroup(getStep).answers" :answer="answer" :type="getQuestionByGroup(getStep).type" />
          </div>
        </div>

        <Actions />
      </div>
    </div>
  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import Actions from "@/components/Actions";
import Answer from "@/components/Answer";

export default {
  name: 'Questions',
  components: {Answer, Actions},
  computed: {
    ...mapGetters(["getStep", "getQuestions", "getQuestionByGroup", "getQuestionsCount"]),
  },
}
</script>

<style scoped lang="scss">
.answers {
  display: flex;
  flex-direction: column;
  &_grid {
    display: grid;
    grid-column-gap: 20px;
    grid-template-columns: repeat(4, 1fr);
    @media screen and (max-width: 992px) {
      grid-row-gap: 56px;
      grid-template-columns: repeat(2, 1fr);
    }
    @media screen and (max-width: 768px) {
      grid-row-gap: 24px;
      grid-template-columns: repeat(1, 1fr);
    }
  }
}

.questions {
  padding: 80px 0;
  background-color: $primary-background;
  @media screen and (max-width: 768px) {
    padding: 32px 0 47px;
  }
  &__inner {
    display: flex;
    flex-direction: column;
  }
  &__header {
    display: flex;
    margin-bottom: 56px;
    @media screen and (max-width: 768px) {
      margin-bottom: 33px;
      flex-direction: column;
    }
  }
  &__title {
    font-size: 32px;
    font-weight: 700;
    max-width: 800px;
    line-height: 40px;
    @media screen and (max-width: 768px) {
      font-size: 24px;
      margin-top: 16px;
      line-height: 28px;
    }
    span {
      color: $primary-color !important;
    }
  }
  &__desc {
    font-size: 20px;
    margin-top: 24px;
    line-height: 34px;
    color: $secondary-text;
    @media screen and (max-width: 768px) {
      font-size: 16px;
      line-height: 22px;
    }
  }
}

.questions-counts {
  width: 72px;
  height: 72px;
  display: flex;
  flex: 0 0 72px;
  margin-right: 56px;
  border-radius: 50%;
  align-items: center;
  justify-content: center;
  border: 1px solid #A8ABC1;
  p {
    display: flex;
    font-size: 28px;
    line-height: 38px;
    align-items: center;
    color: $secondary-text;
  }
  span {
    font-weight: 700;
    color: $primary-text;
  }
}
</style>