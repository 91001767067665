export default {
    state: {
        step: 0
    },
    mutations: {
        changeStep(state, method) {
            if (method) {
                state.step++;
            } else {
                state.step--;
            }
        },
        setStep(state, step) {
            state.step = step;
        }
    },
    actions: {},
    getters: {
        getStep(state) {
            return state.step;
        },
        getPercent: (state) => (full, rounded = false) => {
            let percent = (full) ? 100 : ((100 / 6) * (state.step - 1));

            if (rounded) {
                return Math.trunc(percent);
            } else {
                return percent;
            }
        }
    }
}