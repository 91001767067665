<template>
  <div>
    <main-layout>
      <Progress :full="true" />
      <div class="result">
        <div class="container">
          <div class="result__inner" v-if="getResult === null && getResult !== false">
            <h2 class="result__title">Ожидайте загрузки данных...</h2>
          </div>
          <div class="result__inner" v-if="getResult === false && getResult !== null">
            <h2 class="result__title">Ошибка загрузки данных.<br>Попробуйте зайти позже.</h2>
          </div>
          <div class="result__inner" v-if="getResult !== null && getResult !== false">
            <h2 class="result__title">Результаты тестирования</h2>
            <div class="result-block">
              <h3 class="result-block__title">На Ваш выбор профессии одинаковое влияют как внутренние индивидуальные, так и внутренние социальные мотивы.</h3>
              <div class="result-block__grid result-block__grid_two">
                <div class="result-block__cell">
                  <div class="result-block__motive">
                    <motive-chart :chart-data="getResult.motivs.data" :height="100" />
                  </div>
                </div>
                <div class="result-block__cell">
                  <p class="result-block__text" v-html="getResult.degree.pm"></p>
                </div>
              </div>
            </div>
            <div class="result-block">
              <h3 class="result-block__title">Ваша форма — {{ getResult.figure.desc }}</h3>
              <div class="result-block__grid result-block__grid_small">
                <div class="result-block__cell">
                  <div class="result-block__figure">
                    <img :src="'img/' + getResult.figure.type + '.png'" :alt="getResult.figure.desc">
                  </div>
                </div>
                <div class="result-block__cell" v-html="getResult.figure.meaning"></div>
              </div>
            </div>
            <div class="result-block">
              <h3 class="result-block__title">{{ getResult.couple.desc }}</h3>
              <div class="result-block__grid result-block__grid_one">
                <div class="result-block__cell result-block__cell_social">
                  <social-chart :chart-data="getResult.socials.data" :height="160" />
                </div>
                <div class="result-block__cell" v-html="getResult.couple.meaning"></div>
              </div>
            </div>
            <div class="result-block">
              <h3 class="result-block__title">{{ getResult.twain.desc }}</h3>
              <div class="result-percents">
                <div class="result-percents__item" v-for="(percent, type) in getResult.person">
                  <h4 class="result-percents__label" v-if="type === 'info'">Человек — информация</h4>
                  <h4 class="result-percents__label" v-if="type === 'iscus'">Человек — искусство</h4>
                  <h4 class="result-percents__label" v-if="type === 'chel'">Человек — человек</h4>
                  <h4 class="result-percents__label" v-if="type === 'prirod'">Человек — природа</h4>
                  <h4 class="result-percents__label" v-if="type === 'tech'">Человек — технология</h4>
                  <div class="result-percents__line">
                    <span>{{ Math.trunc(percent) }}%</span>
                    <div class="result-percents__box">
                      <div :style="'width: ' + percent + '%'"></div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="result-block__html" v-html="getResult.twain.meaning"></div>
            </div>
            <div class="result-block">
              <h3 class="result-block__title">Профессии, которые могут вам подойти</h3>
              <ul class="result-block__list">
                <li class="result-block__item" v-for="profession in getResult.professions">{{ profession }}</li>
              </ul>
              <hr>
              <h4 class="result-block__subtitle">Мы подобрали несколько программ обучения специально для вас</h4>
              <p class="result-block__text">В ближайшее время наш специалист свяжется с вами, чтобы рассказать подробнее.</p>
            </div>
            <div class="result-actions">
              <div class="result-actions__block">
                <a href="javascript:window.print();" class="result-actions__button result-actions__button_primary">
                  <svg width="24" height="22" viewBox="0 0 24 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M3.75 2C3.75 1.17157 4.42157 0.5 5.25 0.5H18.75C19.5784 0.5 20.25 1.17157 20.25 2V4.25H20.8691C21.5096 4.25 22.1174 4.51504 22.5611 4.97622C23.0037 5.43634 23.2481 6.05427 23.25 6.69303L23.25 6.69521L23.25 13.8048L23.25 13.807C23.2481 14.4457 23.0037 15.0637 22.5611 15.5238C22.1174 15.985 21.5096 16.25 20.8691 16.25H20.25V20C20.25 20.8284 19.5784 21.5 18.75 21.5H5.25C4.42157 21.5 3.75 20.8284 3.75 20V16.25H3.13093C2.49041 16.25 1.88264 15.985 1.43895 15.5238C0.99627 15.0637 0.751854 14.4457 0.750003 13.807L0.75 13.8048V6.69303C0.751851 6.05427 0.99627 5.43634 1.43895 4.97622C1.88264 4.51504 2.49041 4.25 3.13092 4.25H3.75V2ZM5.25 15.4991L5.25 15.5L5.25 15.5009V20H18.75L18.75 15.5L18.75 12.5H5.25V15.4991ZM20.25 14.75V12.5C20.25 11.6716 19.5784 11 18.75 11H5.25C4.42157 11 3.75 11.6716 3.75 12.5V14.75H3.13092C2.90805 14.75 2.68765 14.6582 2.5199 14.4838C2.35135 14.3086 2.25099 14.0646 2.25 13.8036V6.69645C2.25099 6.43542 2.35135 6.19138 2.5199 6.01619C2.68765 5.84183 2.90805 5.75 3.13092 5.75H20.8691C21.092 5.75 21.3124 5.84183 21.4801 6.01619C21.6487 6.1914 21.749 6.43547 21.75 6.69652V13.8035C21.749 14.0645 21.6487 14.3086 21.4801 14.4838C21.3124 14.6582 21.092 14.75 20.8691 14.75H20.25ZM18.75 2V4.25H5.25V2H18.75ZM6.75 14.75C6.75 14.3358 7.08579 14 7.5 14H16.5C16.9142 14 17.25 14.3358 17.25 14.75C17.25 15.1642 16.9142 15.5 16.5 15.5H7.5C7.08579 15.5 6.75 15.1642 6.75 14.75ZM7.5 17C7.08579 17 6.75 17.3358 6.75 17.75C6.75 18.1642 7.08579 18.5 7.5 18.5H16.5C16.9142 18.5 17.25 18.1642 17.25 17.75C17.25 17.3358 16.9142 17 16.5 17H7.5Z" fill="currentColor"/>
                  </svg>
                  <span>Распечатать результаты</span>
                </a>
                <button class="result-actions__button result-actions__button_outline" v-on:click="start">
                  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M20.3927 3.01053C20.4298 2.45949 20.0131 1.98273 19.4621 1.94565C18.9111 1.90858 18.4343 2.32523 18.3972 2.87626L18.2356 5.27878C17.228 4.37554 16.0269 3.69889 14.7123 3.30767C12.6701 2.69991 10.4804 2.81853 8.51576 3.64335C6.55117 4.46817 4.93306 5.94823 3.93676 7.83167C2.94047 9.71511 2.62756 11.8856 3.05126 13.9737C3.47496 16.0619 4.60911 17.9388 6.26071 19.2849C7.91232 20.6311 9.97933 21.3634 12.11 21.3573C14.2408 21.3511 16.3035 20.6069 17.9473 19.2512C19.5911 17.8955 20.7144 16.0122 21.1261 13.9216C21.2328 13.3797 20.88 12.8539 20.3381 12.7472C19.7962 12.6405 19.2704 12.9933 19.1637 13.5352C18.8414 15.1721 17.9619 16.6468 16.6748 17.7082C15.3877 18.7697 13.7726 19.3525 12.1043 19.3573C10.4359 19.3621 8.81749 18.7887 7.52429 17.7346C6.2311 16.6806 5.34307 15.2111 5.01132 13.576C4.67956 11.941 4.92457 10.2416 5.70466 8.76684C6.48475 7.29212 7.75172 6.13325 9.28998 5.48742C10.8282 4.84159 12.5428 4.74871 14.1418 5.22458C15.1039 5.51089 15.9884 5.99245 16.744 6.63162L14.2879 6.46446C13.7369 6.42696 13.2598 6.84324 13.2223 7.39425C13.1848 7.94526 13.6011 8.42234 14.1521 8.45984L19.0008 8.78984C19.2656 8.80786 19.5266 8.71992 19.7265 8.54538C19.9263 8.37085 20.0487 8.12403 20.0665 7.85928L20.3927 3.01053Z" fill="currentColor"/>
                  </svg>
                  <span>Пройти заново</span>
                </button>
              </div>
              <div class="result-actions__block"></div>
            </div>
          </div>
        </div>
      </div>
    </main-layout>
  </div>
</template>

<script>
import Progress from "@/components/Progress";
import MainLayout from "@/layouts/MainLayout";
import SocialChart from "@/charts/SocialChart";
import MotiveChart from "@/charts/MotiveChart";
import { mapGetters } from "vuex";

export default {
  name: 'result-page',
  components: {
    MotiveChart,
    SocialChart,
    Progress,
    MainLayout
  },
  computed: mapGetters(["getResult", "getStep"]),
  methods: {
    start() {
      this.$router.push({name: 'test'})
    }
  },
  mounted() {
    let resultId = this.$route.params.id;
    if (resultId) {
      this.$store.dispatch('fetchResult', resultId);
    }
  }
}
</script>

<style lang="scss">
.result-actions {
  display: flex;
  margin-top: 36px;
  align-items: center;
  @media print {
    display: none;
  }
  @media screen and (max-width: 768px) {
    margin-top: 6px;
  }
  &__block {
    display: flex;
    align-items: center;
    @media screen and (max-width: 768px) {
      flex-direction: column;
    }
  }
  &__button {
    outline: none;
    display: flex;
    cursor: pointer;
    font-size: 18px;
    font-weight: 700;
    line-height: 27px;
    padding: 14px 40px;
    border-radius: 4px;
    align-items: center;
    white-space: nowrap;
    text-decoration: none;
    transition: .15s linear;
    border: 1px solid transparent;
    background-color: transparent;
    @media screen and (max-width: 768px) {
      width: 100%;
      padding: 14px 20px;
      justify-content: center;
    }
    svg {
      &:not(:last-child) {
        margin-right: 8px;
      }
      &:not(:first-child) {
        margin-left: 8px;
      }
    }
    &:not(:last-child) {
      margin-right: 20px;
      @media screen and (max-width: 768px) {
        margin-right: 0;
        margin-bottom: 20px;
      }
    }
    &_primary {
      color: #fff;
      background-color: $primary-color;
      &:hover {
        opacity: .8;
      }
    }
    &_outline {
      border-color: #9FAEBF;
      &:hover {
        color: #fff;
        background-color: #9FAEBF;
      }
    }
  }
}

.result-types {
  display: flex;
  flex-direction: column;
  &__item {
    display: flex;
    align-items: center;
    @media screen and (max-width: 768px) {
      flex-direction: column;
      align-items: flex-start;
    }
    &:not(:last-child) {
      margin-bottom: 12px;
      @media screen and (max-width: 768px) {
        margin-bottom: 24px;
      }
    }
    b {
      font-size: 20px;
      font-weight: 700;
      line-height: 20px;
      margin-right: 6px;
      @media screen and (max-width: 768px) {
        margin-right: 0;
        margin-bottom: 8px;
      }
    }
    span {
      font-size: 18px;
      line-height: 27px;
    }
  }
}

.result-percents {
  display: flex;
  margin-bottom: 56px;
  flex-direction: column;
  @media print {
    margin-bottom: 15px;
  }
  @media screen and (max-width: 768px) {
    margin-bottom: 36px;
  }
  &__item {
    display: flex;
    align-items: center;
    &:not(:last-of-type) {
      margin-bottom: 20px;
      @media print {
        margin-bottom: 10px;
      }
    }
    @media screen and (max-width: 768px) {
      flex-direction: column;
    }
  }
  &__label {
    width: 240px;
    font-size: 18px;
    flex: 0 0 240px;
    font-weight: 700;
    line-height: 27px;
    margin-right: 20px;
    @media screen and (max-width: 768px) {
      width: 100%;
      flex: 0 0 100%;
      margin-right: 0;
      margin-bottom: 8px;
    }
  }
  &__line {
    flex-grow: 1;
    display: flex;
    align-items: center;
    @media screen and (max-width: 768px) {
      width: 100%;
    }
    span {
      width: 38px;
      flex: 0 0 38px;
      font-size: 18px;
      line-height: 27px;
      margin-right: 12px;
    }
  }
  &__box {
    height: 36px;
    flex-grow: 1;
    overflow: hidden;
    border-radius: 4px;
    background-color: #F3F6FD;
    & > div {
      height: 100%;
      border-radius: 4px;
      background-color: #244CC8;
    }
  }
}

.result {
  padding: 80px 0 100px;
  background-color: $primary-background;
  @media screen and (max-width: 992px) {
    padding: 48px 0 58px;
    background-color: #fff;
  }
  @media print {
    padding: 20px 0;
  }
  &__inner {
    display: flex;
    flex-direction: column;
  }
  &__title {
    font-size: 48px;
    font-weight: 700;
    line-height: 56px;
    text-align: center;
    align-self: center;
    margin-bottom: 56px;
    @media screen and (max-width: 992px) {
      text-align: left;
      margin-bottom: 36px;
      align-self: flex-start;
    }
    @media screen and (max-width: 768px) {
      font-size: 24px;
      line-height: 28px;
    }
    @media print {
      text-align: left;
      margin-bottom: 36px;
      align-self: flex-start;
    }
  }
  &-block {
    display: flex;
    border-radius: 4px;
    background-color: #fff;
    flex-direction: column;
    padding: 64px 100px 72px;
    @media screen and (max-width: 992px) {
      padding: 0;
    }
    @media print {
      padding: 10px;
    }
    &:not(:last-child) {
      margin-bottom: 20px;
      @media screen and (max-width: 992px) {
        margin-bottom: 40px;
      }
    }
    hr {
      height: 1px;
      border: none;
      margin: 48px 0;
      background-color: #CFD1DF;
      @media screen and (max-width: 768px) {
        margin: 32px 0;
      }
      @media print {
        margin: 12px 0;
      }
    }
    dl {
      margin-top: 16px;
    }
    dt {
      font-size: 20px;
      font-weight: 700;
      line-height: 20px;
      margin-bottom: 20px;
    }
    dd {
      font-size: 18px;
      line-height: 27px;
      &:not(:last-of-type) {
        margin-bottom: 40px;
      }
    }
    &__figure {
      width: 280px;
      height: 280px;
      display: flex;
      border-radius: 4px;
      align-items: center;
      justify-content: center;
      background-color: #F3F6FD;
      @media screen and (max-width: 992px) {
        width: 100%;
        margin-bottom: 36px;
      }
      img {
        max-width: 100%;
        max-height: 100%;
      }
    }
    &__grid {
      width: 100%;
      display: grid;
      position: relative;
      grid-column-gap: 64px;
      &_two {
        grid-template-columns: repeat(2, 1fr);
        @media print {
          grid-template-columns: repeat(1, 1fr);
        }
        @media screen and (max-width: 992px) {
          grid-template-columns: repeat(1, 1fr);
        }
      }
      &_small {
        grid-template-columns: 280px 1fr;
        @media print {
          grid-template-columns: 1fr;
        }
        @media screen and (max-width: 992px) {
          grid-template-columns: 1fr;
        }
        .result-block__cell {
          p {
            font-size: 18px;
            line-height: 27px;
            &:not(:last-child) {
              margin-bottom: 20px;
            }
          }
        }
      }
    }
    &__motive {
      height: 100%;
      display: flex;
      position: relative;
      border-radius: 4px;
      padding: 24px 20px;
      align-items: center;
      justify-content: center;
      background-color: #F3F6FD;
      @media screen and (max-width: 992px) {
        width: 100%;
        margin-bottom: 36px;
        height: calc(100% - 36px);
      }
      @media print {
        width: 100%;
        margin-bottom: 36px;
        height: calc(100% - 36px);
      }
    }
    &__cell {
      width: 100%;
      overflow: hidden;
      &_social {
        width: 100%;
        position: relative;
        border-radius: 4px;
        margin-bottom: 60px;
        padding: 36px 0 50px;
        background-color: #F3F6FD;
        @media print {
          display: none;
        }
      }
    }
    &__text {
      font-size: 18px;
      line-height: 27px;
    }
    &__html {
      display: flex;
      flex-direction: column;
    }
    &__title {
      font-size: 32px;
      font-weight: 700;
      line-height: 40px;
      margin-bottom: 56px;
      @media screen and (max-width: 768px) {
        font-size: 24px;
        line-height: 28px;
        margin-bottom: 36px;
      }
      @media print {
        font-size: 24px;
        line-height: 28px;
        margin-bottom: 36px;
      }
    }
    &__subtitle {
      font-size: 28px;
      font-weight: 700;
      line-height: 36px;
      margin-bottom: 24px;
    }
    &__list {
      list-style: disc inside;
    }
    &__item {
      font-size: 20px;
      line-height: 34px;
    }
  }
}
</style>