<template>
  <header class="header">
    <div class="container">
      <div class="header__inner">
        <h1 class="header__title">Профориентационное тестирование</h1>
        <h2 class="header__subtitle" v-show="getStep === 0 && this.$route.name !== 'result'">Пройдите тест и узнайте, какая профессия вам больше подходит</h2>
        <p class="header__desc" v-show="getStep === 0 && this.$route.name !== 'result'" v-html="getDesc"></p>
      </div>
    </div>
  </header>
</template>

<script>
import {mapGetters} from 'vuex'

export default {
  name: 'Header',
  computed: {
    ...mapGetters(["getStep"]),
    getDesc() {
      return headerDesc;
    }
  },
}
</script>

<style lang="scss" scoped>
  .header {
    padding: 48px 0 24px;
    @media screen and (max-width: 768px) {
      padding: 32px 0 0;
    }
    &__inner {
      display: flex;
      margin: 0 auto;
      align-items: center;
      flex-direction: column;
    }
    &__title, &__subtitle, &__desc {
      text-align: center;
    }
    &__title {
      font-size: 72px;
      font-weight: 700;
      margin-bottom: 56px;
      color: $primary-color;
      @media screen and (max-width: 992px) {

      }
      @media screen and (max-width: 768px) {
        font-size: 28px;
        line-height: 32px;
        margin-bottom: 25px;
      }
    }
    &__subtitle {
      font-size: 32px;
      font-weight: 700;
      line-height: 40px;
      margin-bottom: 20px;
      @media screen and (max-width: 768px) {
        font-size: 18px;
        line-height: 22px;
      }
    }
    &__desc {
      font-size: 24px;
      line-height: 34px;
      margin-bottom: 40px;
      @media screen and (max-width: 768px) {
        font-size: 16px;
        line-height: 22px;
      }
    }
  }
</style>
